import { ChatAltIcon } from "@heroicons/react/solid"
import { ComponentPropsWithRef } from "react"
import { classNames } from "lib/classNames"

export function SupportChatButton(props: ComponentPropsWithRef<"div">) {
  const { className, ...otherProps } = props

  return (
    <div
      className={classNames(
        "support-chat-launcher bg-cobalt-400 dark:bg-cobalt-300 hover:bg-cobalt-300 dark:hover:bg-cobalt-400  flex h-10 w-10 cursor-pointer items-center justify-center rounded-full text-white drop-shadow-xl",
        className
      )}
      {...otherProps}
    >
      <ChatAltIcon className="h-6 w-6" />
    </div>
  )
}
