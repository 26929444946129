export function Disclaimer() {
  return (
    <p className="text-center text-xs text-gray-300 dark:text-gray-300">
      By continuing, you agree to our <TermsLink /> and <PrivacyLink />
    </p>
  )
}

function TermsLink() {
  return (
    <a
      className="underline"
      href="https://www.getrundoo.com/terms"
      target="_blank"
    >
      Terms &amp; Conditions
    </a>
  )
}

function PrivacyLink() {
  return (
    <a
      className="underline"
      href="https://www.getrundoo.com/privacy"
      target="_blank"
    >
      Privacy Notice
    </a>
  )
}
