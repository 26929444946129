import { useEffect, useState } from "react"

import { Disclaimer } from "./Disclaimer"
import { RundooLogo } from "components/Assets"
import { SignInCard } from "./SignInCard"
import { SignInProvider } from "./SignInContext"
import { clearState } from "./authSlice"
import { useAppDispatch } from "app/hooks"

export function SignIn() {
  const dispatch = useAppDispatch()

  const [isStateCleared, setIsStateCleared] = useState(false)
  if (!isStateCleared) {
    dispatch(clearState())
    setIsStateCleared(true)
  }

  useEffect(() => {
    if (typeof window.Intercom !== "function") return
    window.Intercom("shutdown")
    if (!window.intercomSettings) return
    window.Intercom("boot", window.intercomSettings)
  }, [])

  return (
    <SignInProvider redirectPath="/pos/sales/new">
      <div className="dark:bg-gray-1000 flex h-screen w-screen flex-col items-center justify-between gap-y-8 overflow-y-auto bg-gray-800 py-4">
        <RundooLogo width={127} height={24} />
        <SignInCard />
        <Disclaimer />
      </div>
    </SignInProvider>
  )
}
