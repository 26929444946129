import NextHead from "next/head"

export function RundooHead() {
  return (
    <NextHead>
      <title>Rundoo</title>
      <link rel="icon" href="/favicon.ico" />
    </NextHead>
  )
}
