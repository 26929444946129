import { AssetProps } from "./types"
import Image from "next/image"

interface RundooWordmarkProps extends AssetProps {
  color: "white" | "black"
}

export function RundooWordmark(props: RundooWordmarkProps) {
  const whiteWordmarkSrc = "/wordmark-white.svg"
  const blackWordkMarkSrc = "/wordmark-black.svg"

  return (
    <Image
      src={props.color === "white" ? whiteWordmarkSrc : blackWordkMarkSrc}
      alt="Rundoo"
      {...props}
    />
  )
}
