import { AssetProps } from "./types"
import Image from "next/image"

interface VisaLogoProps extends AssetProps {
  color: "white" | "black"
}

export function VisaLogo(props: VisaLogoProps) {
  const whiteLogoSrc = "/visa-white.svg"
  const blackLogoSrc = "/visa-black.svg"

  return (
    <Image
      src={props.color === "white" ? whiteLogoSrc : blackLogoSrc}
      alt="Visa Logo"
      {...props}
    />
  )
}
